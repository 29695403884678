module.exports = {
	HOME: '/',
	HELP: '/help',

	CUSTOMIZE: '/user/customize',

	EMOTES: '/emotes',

	CAPTCHA: '/captcha',

	REPORT: '/report',

	STATUS: '/status',

	BATTLE_LOG: '/battle-log',

	STORE: '/store',
	CHECKOUT: '/checkout',
	CONFIRMATION: '/confirmation',

	TERMS_OF_SERVICE: '/terms-of-service',
	PRIVACY_POLICY: '/privacy-policy',
	RETURN_POLICY: '/return-policy',
	DISCLAIMER: '/disclaimer',

	ADMIN: '/admin',
	ADMIN_TRANSACTION: '/admin/transaction',
	ADMIN_TRANSACTION_LOGS: '/admin/transaction-logs',
	ADMIN_PENDING: '/admin/pending',
	ADMIN_CUSTOMIZED: '/admin/customized',
	ADMIN_REPORT_USER: '/admin/report/user',
	ADMIN_REPORT_GUILD: '/admin/report/guild',
	ADMIN_REPORT_APPEAL: '/admin/report/appeal',
};
