<template>
	<Particles
		id="tsparticles"
		:particlesInit="particlesInit"
		:particlesLoaded="particlesLoaded"
		:options="options"
	/>
</template>

<script>
import { loadConfettiShape } from 'tsparticles-shape-confetti';

export default {
	name: 'ParticalConfetti',
	props: {
		quantity: {
			type: Number,
			default: 10,
		},
		duration: {
			type: Number,
			default: 1,
		},
	},
	data: () => ({
		options: {
			fullScreen: {
				enabled: true,
			},
			fpsLimit: 60,
			detectRetina: true,
			interactivity: {
				detectsOn: 'canvas',
				events: {
					resize: true,
				},
			},
			particles: {
				number: {
					value: 0,
				},
				color: {
					value: ['#1E00FF', '#FF0061', '#E1FF00', '#00FF9E'],
				},
				shape: {
					type: 'confetti',
					options: {
						confetti: {
							type: ['circle', 'square'],
						},
					},
				},
				opacity: {
					value: {
						max: 1,
						min: 0,
					},
					animation: {
						enable: true,
						speed: 2,
						startValue: 'max',
						destroy: 'min',
					},
				},
				size: {
					value: { min: 3, max: 7 },
				},
				life: {
					duration: {
						sync: true,
						value: 5,
					},
					count: 1,
				},
				move: {
					enable: true,
					gravity: {
						enable: true,
						acceleration: 20,
					},
					speed: {
						min: 25,
						max: 50,
					},
					drift: {
						min: -2,
						max: 2,
					},
					decay: 0.05,
					direction: 'none',
					outModes: {
						default: 'destroy',
						top: 'none',
					},
				},
			},
			responsive: [
				{
					maxWidth: 700,
					options: {
						particles: {
							move: {
								speed: 30,
								decay: 0.05,
							},
						},
					},
				},
			],
			emitters: [
				{
					direction: 'top-right',
					rate: {
						delay: 0.1,
						quantity: 10,
					},
					position: {
						x: 0,
						y: 50,
					},
					size: {
						width: 0,
						height: 0,
					},
					life: {
						count: 1,
						duration: 1,
					},
				},
				{
					direction: 'top-left',
					rate: {
						delay: 0.1,
						quantity: 10,
					},
					position: {
						x: 100,
						y: 50,
					},
					size: {
						width: 0,
						height: 0,
					},
					life: {
						count: 1,
						duration: 1,
					},
				},
			],
		},
	}),
	methods: {
		particlesInit(main) {
			loadConfettiShape(main);
		},
		particlesLoaded() {},
	},
	mounted() {
		this.options.emitters.forEach((emitter) => {
			emitter.rate.quantity = this.quantity;
			emitter.life.duration = this.duration;
		});
	},
};
</script>

<style scoped>
#tsparticles {
	position: fixed;
	z-index: 9999;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	margin: 0;
	pointer-events: none;
	touch-action: none;
}

#tsparticles >>> canvas {
	pointer-events: none !important;
	touch-action: none !important;
}
</style>
