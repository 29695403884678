var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
		callback: _vm.visible,
		intersection: {
			threshold: _vm.isMobile ? 0.95 : 0.55,
		},
		once: true,
		throttle: 300,
	}),expression:"{\n\t\tcallback: visible,\n\t\tintersection: {\n\t\t\tthreshold: isMobile ? 0.95 : 0.55,\n\t\t},\n\t\tonce: true,\n\t\tthrottle: 300,\n\t}"}],class:_vm.isMobile ? 'panel-mobile' : 'panel'},[_c('v-scroll-y-reverse-transition',[(_vm.show)?_c('div',{class:{
				'panel-contents': !_vm.isMobile,
				'panel-contents-mobile': _vm.isMobile,
				flip: _vm.flip && !_vm.isMobile,
			}},[_vm._t("default"),_c('div',{staticClass:"padding"}),_c('div',{class:_vm.isMobile ? 'panel-text-mobile' : 'panel-text'},[_c('div',{staticClass:"body-title text-h3 primary--text"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{staticClass:"text-body"},[_vm._v(" "+_vm._s(_vm.desc)+" ")])])],2):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }